h2#sentry-error-embed-heading {
    font-size: 50px;
}

div.sentry-error-embed p {
    font-family: "Myriad Pro Regular", Helvetica, Arial, sans-serif;
}

div.sentry-error-embed p span {
    font-family: "Myriad Pro Regular", Helvetica, Arial, sans-serif;
}